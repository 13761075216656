import React from 'react';

function LiteratureReview(props) {


	return(
		<embed src={`${process.env.PUBLIC_URL}/Caltrans ATP B C Tool Literature Review Final Draft.pdf`} width="100%" height="800px" />
	);
}

export default LiteratureReview;
