import React from 'react';

function Training(props) {


	return(
		<>
		<div className="container mt-4">
			<h1>Training</h1>
			<p>Coming soon</p>
		</div>
		</>
	);
}

export default Training;
